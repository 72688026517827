const data = {
    menuItems: [
        {
            name: '系统首页',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        {
            name: '证书管理',
            path: '/main/certs',
            icon: 'el-icon-s-management',
        },
        {
            name: '邮件记录',
            path: '/main/maillog',
            icon: 'el-icon-message',
        },
        {
            name: '系统设置',
            path: '/main/mail_tmpl',
            icon: 'el-icon-setting',
            children: [
                {
                    name: '邮件设置',
                    path: '/main/mail_tmpl/index',
                    icon: 'el-icon-menu',
                },
                {
                    name: '修改密码',
                    path: '/main/settings/password',
                    icon: 'el-icon-user',
                },
            ]
        },
        {
            name: '使用说明',
            path: '/main/help',
            icon: 'el-icon-help',
        }
    ]
}

export default data
