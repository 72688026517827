import { render, staticRenderFns } from "./layout_admin.vue?vue&type=template&id=e04b845e&scoped=true&"
import script from "./layout_admin.vue?vue&type=script&lang=js&"
export * from "./layout_admin.vue?vue&type=script&lang=js&"
import style0 from "./layout_admin.vue?vue&type=style&index=0&id=e04b845e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e04b845e",
  null
  
)

export default component.exports