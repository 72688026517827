<template>
    <base-layout title="人民文博证书生成工具" :logo="logo">
        <el-aside width="240px">
            <aside-menu :default-active="$route.path" :menuItems="menuItems" />
        </el-aside>
        <el-main>
            <router-view></router-view>
        </el-main>
    </base-layout>
</template>

<script>
    import Logo from "@/assets/logo0.png"
    import BaseLayout from "./components/baseLayout"
    import AsideMenu from "./components/asideMenu"
    import menu from "@/data/menu"
    export default {
        components: {
            BaseLayout,
            AsideMenu
        },
        data() {
            return {
                logo: Logo,
                menuItems: menu.menuItems
            }
        }
    }
</script>

<style lang="scss" scoped>
    .el-aside {
        /*background-color: #eeeeee;*/
        color: #333;
        border-right: 1px solid #e6e6e6;
    }
</style>
